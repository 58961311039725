<template>
  <v-card width="400" class="mx-auto mt-5">
    <v-card-title class="align-center">
      <!-- <h1 class="display-1 center">Ingrese Documento</h1> -->
      Ingrese Nº Documento
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-alert dense v-if="error" type="error"
        >No se pudo registrar la fichada.</v-alert
      >
      <v-form @submit.prevent>
        <v-row class="firstrow">
          <v-col>
            <v-text-field
              class="dnidisplay"
              outlined
              readonly
              v-model.number="documento"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Numeric Key -->
        <v-row>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '1'"
              >1</v-btn
            >
          </v-col>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '2'"
              >2</v-btn
            ></v-col
          >
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '3'"
              >3</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '4'"
              >4</v-btn
            >
          </v-col>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '5'"
              >5</v-btn
            >
          </v-col>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '6'"
              >6</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '7'"
              >7</v-btn
            >
          </v-col>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '8'"
              >8</v-btn
            >
          </v-col>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '9'"
              >9</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento = documento.slice(0, -1)"
              ><v-icon>mdi-backspace</v-icon></v-btn
            >
          </v-col>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="documento += '0'"
              >0</v-btn
            >
          </v-col>
          <v-col
            ><v-btn
              class="keypad_btn"
              :disabled="btnDisable"
              block
              @click="resetForm"
              ><v-icon> mdi-cached</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <br />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            color="primary"
            block
            @click="ficharFormSubmit"
          >
            <v-icon left>mdi-account-check</v-icon>Fichar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import RelojService from "@/services/RelojService.js";

export default {
  name: "RelojKeyPad",
  data() {
    return {
      documento: "",
      error: false,
      loading: false,
      btnDisable: false,
    };
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (!this.loading) {
        if (e.key == "0") this.documento += "0";
        else if (e.key == "1") this.documento += "1";
        else if (e.key == "2") this.documento += "2";
        else if (e.key == "3") this.documento += "3";
        else if (e.key == "4") this.documento += "4";
        else if (e.key == "5") this.documento += "5";
        else if (e.key == "6") this.documento += "6";
        else if (e.key == "7") this.documento += "7";
        else if (e.key == "8") this.documento += "8";
        else if (e.key == "9") this.documento += "9";
        else if (e.key == "Backspace")
          this.documento = this.documento.slice(0, -1);
        else if (e.key == "Enter") this.ficharFormSubmit();
      }
    });
  },
  methods: {
    resetForm() {
      this.documento = "";
      this.error = false;
      this.loading = false;
      this.btnDisable = false;
    },

    ficharFormSubmit() {
      if (this.loading) return;
      if (!this.documento) return;
      this.error = false;
      this.loading = true;
      this.btnDisable = true;
      this.$emit("documento-changed", this.documento);
    },
  },
};
</script>

<style scope>
.firstrow {
  margin-bottom: -50px;
}
.dnidisplay input {
  text-align: center !important;
  font-size: 26pt !important;
}
.keypad_btn {
  font-size: 20pt !important;
}
</style>
