<template>
  <v-container>
    <v-alert dense v-if="error" type="error">{{ error }}</v-alert>
    <div v-if="this.dispositivo">
      <RelojComponent :dispositivo="this.dispositivo"> </RelojComponent>
    </div>
    <div v-else>
      <v-progress-linear
        :active="progress"
        :indeterminate="true"
        absolute
        top
        color="primary lighten"
      ></v-progress-linear>
    </div>
  </v-container>
</template>
<script>
import RelojComponent from "../components/Reloj.vue";
import Helpers from "@/helpers/helpers.js";
import { authComputed } from "../store/helpers.js";
import { version } from "../../package";
import RelojService from "../services/RelojService.js";

export default {
  name: "Ingreso",
  computed: {
    ...authComputed,
  },

  components: {
    RelojComponent,
  },
  data() {
    return {
      dispositivo: null,
      empleados: null,
      appVersion: null,
      versions: null,
      progress: true,
      error: null,
    };
  },
  props: ["documento"],
  created() {
    this.appVersion = version;
    const loggedIn = localStorage.getItem("user");
    let username = JSON.parse(loggedIn).username;

    RelojService.getDispositivoByUser(username)
      .then((response) => {
        this.dispositivo = response.data;
      })
      .catch((error) => {
        this.progress = false;
        this.error = Helpers.handleError(error);

        // this.$router.push({
        //   name: "PermisosError",
        // });
      })
      .then(
        console.log("before getversions"),
        RelojService.getVersions()
          .then((response) => {
            this.versions = response.data;

            if (this.versions.relojWebAppMinVersion > this.appVersion) {
              this.$router.push({
                name: "UpdateMsg",
                params: {
                  minVersion: this.versions.relojWebAppMinVersion,
                  lastVersion: this.versions.relojWebAppLastVersion,
                },
              });
            }
          })
          .catch((error) => {
            console.log("Catch de getversions: " + error);
            //this.errorFichada = Helpers.handleError(error); //error.response.data[0];
          })
      );
  },

  methods: {
    checkPersona(per) {
      this.persona = per;
    },

    OnReset() {
      this.persona = null;
      this.documento = null;
    },

    onSuccess() {
      this.documento = null;
      this.persona = null;
      //this.$router.go();
      //this.$router.push({ name: "Ingreso" });
    },
  },
};
</script>
