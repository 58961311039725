<template>
  <div>
    <RelojKeyPad
      v-if="!persona"
      ref="KeyPad"
      @documento-changed="handleDocumentoChanged"
    ></RelojKeyPad>

    <PersonaCard
      v-if="persona"
      :persona="persona"
      @persona-reset="handleReset"
      @fichar-clicked="handleFichar"
      :error="errorFichada"
      ref="PersonaCard"
    ></PersonaCard>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import RelojService from "@/services/RelojService.js";
import RelojKeyPad from "../components/Reloj/RelojKeyPad";
import PersonaCard from "../components/Reloj/PersonaCard";
import Helpers from "@/helpers/helpers.js";
import { Geolocation } from "@capacitor/geolocation";

export default {
  name: "RelojComponent",
  components: { RelojKeyPad, PersonaCard },
  data() {
    return {
      documento: "",
      error: false,
      errorFichada: "",
      loading: false,
      persona: null,
      snackbar: false,
      timeout: 2000,
      message: "",
      foto: "",
    };
  },
  props: {
    dispositivo: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.dispositivo && this.dispositivo.empleado)
      this.persona = this.dispositivo.empleado;
  },

  methods: {
    async handleFichar(foto) {
      var self = this;
      var geoError = false;
      this.errorFichada = "";
      this.foto = foto;
      let foto_obj = {
        foto: foto,
      };
      let cords_obj = {
        lat: null,
        long: null,
      };

      await Geolocation.getCurrentPosition({
        maximumAge: 1000,
        enableHighAccuracy: true,
      })
        .then(function (pos) {
          cords_obj.lat = pos.coords.latitude;
          cords_obj.long = pos.coords.longitude;
        })
        .catch(function () {
          geoError = true;
        });
      if (geoError) {
        this.loading = false;
        this.errorFichada =
          "No se pudieron obtener coordenadas. Compruebe que la ubicación esté activada y otorgue permisos.";
        return;
      }

      let fichada = {
        foto: foto_obj,
        persona: this.persona.id,
        latitud: cords_obj.lat,
        longitud: cords_obj.long,
      };

      RelojService.postFichada(fichada)
        .then((response) => {
          this.snackbar = true;
          this.message = "Fichada creada correctamente";
          this.error = false;
          this.errorFichada = "";
          this.loading = false;
          this.persona = this.dispositivo.empleado;

          this.$refs.PersonaCard.resetForm();
        })
        .catch((error) => {
          //console.log("El error: " + error.response.data[0]);
          this.loading = false;
          this.errorFichada = Helpers.handleError(error); //error.response.data[0];
        });
    },
    handleReset() {
      this.persona = null;
      this.error = false;
      this.errorFichada = "";
      this.loading = false;
      this.snackbar = false;
    },
    handleDocumentoChanged(documento) {
      RelojService.getEmpleadoByDocumento(documento)
        .then((response) => {
          if (!response.data.count) {
            this.message = "No se encontró el documento.";
            this.snackbar = true;
            this.$refs.KeyPad.resetForm();
            this.return;
          }
          this.persona = response.data.results[0];
        })
        .catch((error) => {
          this.loading = false;
          this.message = Helpers.handleError(error);
          this.snackbar = true;
          this.$refs.KeyPad.resetForm();
          this.return;
        });
    },

    ficharFormSubmit() {
      this.error = false;
      this.loading = true;

      RelojService.getEmpleadoByDocumento(this.documento)
        .then((response) => {
          if (!response.data.count) {
            this.loading = false;
            this.error = true;
            return;
          }

          this.persona = response.data.results[0];
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>
