<template>
  <div v-if="persona">
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title class="align-center"> Bienvenido </v-card-title>
      <v-card-subtitle class="card_subtitle">
        {{ persona.nombres }} {{ persona.apellidos }}
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-alert
          v-if="!error"
          border="top"
          colored-border
          type="info"
          elevation="1"
        >
          Enfoque su rostro y haga clic en el botón Fichar.
        </v-alert>

        <v-alert v-else border="top" colored-border type="error" elevation="1">
          {{ error }}
        </v-alert>

        <div v-if="persona" class="camera-viewport">
          <EasyCamera
            v-model="foto"
            ref="cam"
            :fullscreen="camConfigs"
            :autoDetectMobile="camConfigs"
            :fullscreenZIndex="1"
          >
          </EasyCamera>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn color="primary" block @click="ficharBtn"
              ><div v-if="loading">
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
              <div v-else><v-icon left>mdi-account-check</v-icon>Fichar</div>
            </v-btn>
          </v-col>

          <div v-if="persona.tipo">
            <v-col>
              <v-btn color="error" block @click="$emit('persona-reset')"
                ><v-icon left dark> mdi-refresh </v-icon>Reiniciar</v-btn
              >
            </v-col>
          </div>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import RelojService from "@/services/RelojService.js";
import EasyCamera from "easy-vue-camera";

export default {
  name: "RelojKeyPad",
  components: { EasyCamera },
  data() {
    return {
      documento: "",
      loading: false,
      btnDisable: false,
      foto: null,
      camConfigs: false,
    };
  },
  props: {
    persona: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      required: true,
    },
  },

  methods: {
    ficharBtn() {
      if (this.loading) return;
      this.$refs.cam.snap();
      // if (!this.foto) this.error = true;
      this.loading = true;
      this.$emit("fichar-clicked", this.foto);
    },
    resetForm() {
      this.documento = "";
      this.loading = false;
      this.btnDisable = false;
      this.foto = null;
      this.camConfigs = false;
      this.$refs.cam.start();
    },
  },
  watch: {
    error: function (newVal, oldVal) {
      if (newVal) {
        this.$refs.cam.start();
        this.loading = false;
      }
    },
  },
};
</script>

<style scope>
.video-wrapper {
  height: 220px;
}
.card_subtitle {
  padding-bottom: 0;
}
</style>
